import { useContext, useEffect, useState } from "react";
import { motion } from 'framer-motion'

import {
  ButtonSmileLaptop,
  ButtonSmileMobile,
  EmailIcon,
  PasswordIcon,
  PhoneIcon,
} from "../../../assets/icons/icons";
import { BOT_TOKEN, CHAT_ID, EN_LANGUAGE, PHONE_CODES_LIST, PRIVACY_POLICY_LINK, SELLAVI_API_URL, TERMS_OF_SERVICE_LINK } from "../../../assets/utils/constants";
import Popup from "../../Popup/Popup";
import AuthInput from "../AuthInput/AuthInput";
import InputSelect from "../InputSelect/InputSelect";
import stars from '../../../assets/images/aboutUs/stars.webp'
import blur from '../../../assets/images/aboutUs/blur.webp'
import global from '../../../assets/images/aboutUs/global.webp'
import spy from '../../../assets/images/aboutUs/spy.webp'
import "./SignUp.css";

import terms_of_service from '../../../assets/documents/terms_of_service.pdf'
import privacy_policy from '../../../assets/documents/privacy_policy.pdf'
import { TranslationContext } from "../../../assets/contexts/translationContext";
import { getUTMHistoryString, sendTextTgBot } from "../../../assets/utils/utils";

function SignUp({ isOpen, onClose, values, valuesValidity, onChange }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { popupSignUp } = useContext(TranslationContext);
  const { signupText } = popupSignUp
  const [checked, setChecked] = useState(true)

  useEffect(() => {
    // Preload images
    const preloadImages = [global, spy];
    const promises = preloadImages.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(promises)
      .then(() => {
        setPreloaders((prev) => ({ ...prev, imagesLoaded: true }));
      })
      .catch((error) => {
        console.error('Error preloading images:', error);
      });
  }, []);

  const isDisabled = !(
    checked &&
    valuesValidity.email?.validState &&
    valuesValidity.phone?.validState &&
    valuesValidity.password?.validState
  );

  function openSelectDropdown() {
    setIsDropdownOpen(true);
  }

  function closeSelectDropdown() {
    setIsDropdownOpen(false);
  }

  function handleClose() {
    onClose();
    closeSelectDropdown();
  }

  const [preloaders, setPreloaders] = useState(false)

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    let referrer = localStorage.getItem('referrer') ? localStorage.getItem('referrer') : 'Direct';
    if (referrer.includes('sellavi.com')) {
      referrer = 'Direct';
    }

    const utm_history = getUTMHistoryString();

    // Prepare the message content for Telegram
    const LIMIT_NOTICE = '________\n...TG LIMIT REACHED...\n________';
    const MAX_TG_LENGTH = 2048;
    const RESERVED_LENGTH = LIMIT_NOTICE.length;

    let messageContent = `________\nNew lead\n\nLocale: BG\nPhone: ${values.code.value + values.phone}\nEmail: ${values.email}\nPassword: ${values.password}\nReferrer: ${referrer}${utm_history && utm_history.length > 0 ? `\nUTM: ${utm_history}` : ''}\n________`;

    // Trim the message if it exceeds the Telegram character limit
    if (messageContent.length > MAX_TG_LENGTH) {
      const allowedLength = MAX_TG_LENGTH - RESERVED_LENGTH;
      messageContent = `${messageContent.slice(0, allowedLength)}\n${LIMIT_NOTICE}`;
    }

    // Send the trimmed or original message to Telegram
    sendTextTgBot(CHAT_ID, messageContent, setPreloaders);

    // Proceed with form submission logic
    const form = evt.target;

    // Dynamically create a hidden input field for the referrer
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', 'my_refferal'); // You can change 'my_refferal' to your desired field name
    hiddenInput.setAttribute('value', referrer);

    // Dynamically create a hidden input field for the referrer
    const hiddenInputFullRef = document.createElement('input');
    hiddenInputFullRef.setAttribute('type', 'hidden');
    hiddenInputFullRef.setAttribute('name', 'full_ref'); // You can change 'my_refferal' to your desired field name
    hiddenInputFullRef.setAttribute('value', utm_history && utm_history.length > 0 ? utm_history : 'None');

    // Append the hidden input field to the form
    form.appendChild(hiddenInput);
    form.appendChild(hiddenInputFullRef);

    form.setAttribute('action', `${SELLAVI_API_URL}/login/new_account`);
    form.setAttribute('method', "POST");

    if (isDisabled) return;

    form.querySelector('#phone').value = values.code.value + values.phone;
    form.submit();
    setPreloaders(prevValue => ({
      ...prevValue,
      submit: true,
    }));
  };
  return (
    <section className="signup">
      <Popup onClose={handleClose} {...{ isOpen }} preloader_visible={preloaders.submit}>
       
        <div className="signup__container">
        {preloaders.submit ?
          <div className="signup__preloader">
            <div className='signup__preloader-glob-box'>
              <img alt='' src={global} className='signup__preloader-glob'>
              </img>
              <img alt='' src={spy} className='signup__preloader-spy global__spy_anim'>
              </img>
            </div>

          </div>
          :
          null
        }
          <p className="signup__title">
            {popupSignUp.signupTitle}{" "}
            {/* <p className="signup__title"
                dangerouslySetInnerHTML={{
                  __html: popupSignUp.signupTitle,
                }}
              /> */}
            <ButtonSmileLaptop
              mainClassName="signup__smile-icon signup__smile-icon_type_desc"
              fillClassName="signup__smile-icon-fill"
            />
            <ButtonSmileMobile
              mainClassName={`signup__smile-icon signup__smile-icon_type_mobile ${localStorage.getItem("language") === EN_LANGUAGE ? 'signup__smile-icon_en' : 'signup__smile-icon_bg'}`}
              fillClassName="signup__smile-icon-fill"
            />
          </p>
          <div className="signup__text-box">
            <span className={`signup__text-icon-box`}>
              <svg
                onClick={() => setChecked(!checked)} className={`${checked ? 'signup__text-icon_full' : ''} signup__text-icon`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="11.5" fill="#35C650" stroke="none" />
                <path d="M6 12.5L9.5 16L17.5 8" stroke="white" />
              </svg>
            </span>
            <p className="signup__text">
              <span className={`signup__text-icon-box_mobile`}>
                <svg
                  onClick={() => setChecked(!checked)} className={`${checked ? 'signup__text-icon_full' : ''} signup__text-icon`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="11.5" fill="#35C650" stroke="none" />
                  <path d="M6 12.5L9.5 16L17.5 8" stroke="white" />
                </svg>
              </span>
              {signupText.preambulaTermsService}{' '}
              <a className="signup__text-link" href={terms_of_service} target="_blank" rel="noreferrer">
                {signupText.termsService}
              </a>{" "}
              {signupText.preambulaPrivacyPolicy}{' '}
              <a className="signup__text-link" href={privacy_policy} target="_blank" rel="noreferrer">
                {signupText.privacyPolicy}
              </a>
            </p>
          </div>
          <form
            className="signup__form"
            onSubmit={handleSubmit}
          >
            <div className="signup__inputs-box">
              <InputSelect
                name="code"
                value={values.code}
                selectList={PHONE_CODES_LIST}
                onChange={onChange}
                onOpenDropdown={openSelectDropdown}
                onCloseDropdown={closeSelectDropdown}
                isOpen={isDropdownOpen}
              />
              <AuthInput
                name="phone"
                placeholder="8867186671"
                type="tel"
                inputMode="tel"
                value={values.phone}
                error={valuesValidity.phone}
                icon={PhoneIcon}
                onChange={onChange}
              />
            </div>

            <AuthInput
              name="email"
              placeholder={popupSignUp.inputEmail}
              type="email"
              inputMode="email"
              value={values.email}
              error={valuesValidity.email}
              icon={EmailIcon}
              onChange={onChange}
            />

            <AuthInput
              name="password"
              placeholder={popupSignUp.inputPass}
              type="password"
              value={values.password}
              error={valuesValidity.password}
              icon={PasswordIcon}
              onChange={onChange}
            />

            <button
              className={`signup__submit-btn ${isDisabled ? "signup__submit-btn_disabled" : ""
                }`}
              type="submit"
            >
              <p className="signup__btn-text">{popupSignUp.submitBtn}</p>
            </button>
          </form>
        </div>

      </Popup>
    </section >
  );
}

export default SignUp;
