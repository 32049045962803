export const BG_TRANSLATION = {
    helmet: {
        main: {
            title: 'Изгради и развий своя онлайн магазин със Sellavi – започни днес!',
            desc: `Стартирай своя онлайн магазин със Sellavi – лесно, бързо и без усилия!
            Мощни инструменти, интуитивни интеграции и настройка за минути. Започни да продаваш още днес!`
        },
        about: {
            title: 'За Sellavi | Твоят партньор за успех в електронната търговия!',
            desc: `Sellavi ще ти помогне да превърнеш идеите си в успешен онлайн бизнес!
            Присъедини се към хиляди предприемачи, които растат и печелят в електронната търговия.`
        },
        prices: {
            title: 'Планове и цени | Безплатен 14-дневен пробен период – без скрити такси!',
            desc: `Избери план, който е идеален за теб!
            Без лимит на продуктите, всички необходими интеграции и без скрити такси – стартирай с увереност!`
        },
        partners: {
            title: 'Партньорска програма | Партнирай си със Sellavi и печели повече!',
            desc: `Стани наш партньор и печели със Sellavi!
            Помагай на други да изградят своя онлайн магазин, докато ти получаваш комисионни за всеки нов магазин.`
        },
        blog: {
            title: 'Sellavi Блог | Експертни съвети за увеличаване на твоите онлайн продажби!',
            desc: `Бъди в крак с най-новите тенденции в електронната търговия!
            Чети експертни съвети, анализи и стратегии за повече продажби в блога на Sellavi.`
        }
    },
    header: {
        headerLink: {
            aboutUs: {
                name: 'За нас'
            },
            prices: {
                name: 'Цени'
            },
            affiliateProgram: {
                name: 'Партньорска програма'
            },
            howToThriveInEcomm: {
                name: 'Тайните за e-commerce успех'
            }
        },
        signIn: "Вход",
        language: "Language",
    },
    footer: {
        greenTitle: 'Възползвай се от нашия 14-дневен безплатен пробен период!',
        whiteTitle: 'БЕЗ нужда от кредитна карта',
        subtitle: 'Започни да продаваш още днес!',
        textBtn: 'ЗАПОЧНИ СЕГА',
        freeTrial: 'Тествай безплатно',
        rightsReserved: `Всички права запазени ${new Date().getFullYear()} | © Sellavi`,
        footerLink: {
            title1: 'За нас',
            title2: 'Цени',
            title3: 'Партньорска програма',
            title4: 'Тайните за e-commerce успех',
            mobileTitle4: 'Тайните за e-commerce успех',
        },
        mail: 'info.bg@sellavi.com',
    },

    blogArticle: {
        content: 'Съдържание',
        bannerTitleFirstLine: 'ЗАПОЧНИ ДА ПРОДАВАШ',
        bannerTitleSecondLine: 'СЪС SELLAVI',
        bannerBtnText: 'ОЩЕ ДНЕС',
        greenLineText: 'Ако имаш въпроси, нашият експертен екип по техническа поддръжка с радост ще им отговори.',
        like: 'Like?',
        readTime: 'Време за четене',
        shareSocial: 'Сподели в социалните мрежи',
        min: 'мин.'
    },
    popupSignUp: {
        back: 'Обратно',
        signupTitle: 'Тествай безплатно за 14 дни',
        signupText: {
            preambulaTermsService: 'Със създаването на акаунт ти се съгласяваш с нашите',
            termsService: 'Условия за ползване ↗',
            preambulaPrivacyPolicy: 'и',
            privacyPolicy: 'Политика за поверителност ↗'
        },
        inputEmail: 'имейл адрес',
        inputPass: 'парола',
        submitBtn: 'Създай акаунт'
    },
    main: {
        mainBanner: {
            bannerTitle: 'Създай мечтания онлайн магазин за минути!',
            bannerPlaceholder: 'Имейл адрес',
            bannerBtn: 'Създай безплатно'
        },
        mainNav: {
            navLink: {
                start: {
                    name: 'Създай магазин'
                },
                reviews: {
                    name: 'Клиентски отзиви'
                },
                steps: {
                    name: 'Как да започнеш'
                },
                features: {
                    name: 'Функционалности'
                },
                ai: {
                    name: 'AI търговия'
                },
                examples: {
                    name: 'Успешните ни клиенти'
                },
                benefits: {
                    name: 'Предимствата на Sellavi'
                },
            },
            navBtn: 'Създай безплатно'
        },
        mainReviews: {
            revFirst: {
                name: 'Aнна',
                description: `Избрахме Sellavi заради надеждността и сигурността, които платформата предлага. Преди, поддръжката на нашия магазин беше сред най-големите ни предизвикателства, но Sellavi направи процеса лесен и приятен. Останахме силно впечатлени от подкрепата, която ни оказа екипът на компанията при създаването на онлайн магазина. Отдадеността и търпението на служителите на Sellavi са изключителни, а крайният резултат отразява качеството на предлаганата от тях услуга!`,
            },
            revSecond: {
                name: 'Изабел',
                description: `Избрах Sellavi, защото те са хората, които наистина се вслушват в моите бизнес идеи. 

                Сътрудничеството ни започна след като се свързах с платформата, говорих с мениджъра и ми препоръчаха плана Sellavi Plus. В рамките на броени седмици имах напълно готов онлайн магазин, който съвършено отразява ценностите на бранда ми. Дизайнът и функционалностите (особено тези, свързани с персонализираните продукти, които предлагам) са отлични. 
                
                С радост продължавам да бъда клиент на Sellavi!`,
            },
            revThird: {
                name: 'Ния Николова',
                description: `Комуникацията с екипа на Sellavi е лесна и приятна: те ни предоставят ключови напъствия за поддръжката на нашия магазин и ни помагат да сбъднем визията си. Работата с платформата е изключително лесна благодарение на нейния семпъл и интуитивен интерфейс. Sellavi ни позволява да постигнем оптимални резултати с минимални усилия.`,
            },
            revFour: {
                name: 'Клементина Милева',
                description: `Работата със Sellavi по моя онлайн магазин е фасулски лесна и приятна. Интерфейсът на платформата е изключително семпъл за употреба, а крайният резултат надмина очакванията ми - интернет магазинът ми изглежда невероятно! Получих позитивни отзиви от клиенти веднага след отварянето му.`,
            },
            revFive: {
                name: 'Тодор Ангелов',
                description: `Избрах Sellavi за създаването на своя онлайн магазин, защото платформата предоставя всичко, от което се нуждая: богат набор от красиви сайт дизайни за различни типове магазини, лесна интеграция на инструменти като Google Analytics и Facebook каталог и надеждни инструменти за анализ на трафика. Изключително доволен съм от решението си - бизнесът ми е по-успешен отвсякога! `,
            },
            revSix: {
                name: 'Борислава Георгиева',
                description: `Натъкнах се на Sellavi докато търсех перфектната платформа за построяването на своя онлайн магазин. Грабна ме това колко лесна за употреба е платформата, както и отзивчивостта на екипа. Невероятно доволна съм от крайния резултат и редовно получавам комплименти от приятели и клиенти!`,
            },
            revSeven: {
                name: 'Altinbas',
                description: `Sellavi платформата работи безгрешно! Особено много ми допада раздел "Продукти" - лесен е за употреба, а крайният резултат изглежда великолепно. Нашите клиенти могат удобно да поръчват директно през сайта, което пък опростява работата ни. Sellavi е най-доброто, което можете да направите за вашия бизнес!`,
            },
            revEight: {
                name: 'Миглена',
                description: `Създадох онлайн магазина си със Sellavi и мога уверено да заявя, че съм изключително доволна от всичко, което платформата предлага: комуникацията с екипа, богатия набор от функционалности и лесния за употреба интерфейс. С две ръце препоръчвам платформата на всеки, който търси ефективен и интуитивен начин за създаването на интернет магазин.`,
            },
            revNine: {
                name: 'Кремена Иванова-Милушева',
                description: `Това, че се натъкнах на Sellavi, беше истинско щастие! Преди да започна работа с платформата експериментирах с редица несполучливи опции и бях на прага да се откажа. Sellavi обаче отзивчиво и търпеливо ме подкрепи и отговори на питанията ми. Всички членове на екипа имат благ и работлив характер, а фокусът им върху клиентите те кара да се чувстваш истински ценен!`,
            },
            revTen: {
                name: 'Габриела',
                description: `Любимото ми нещо относно Sellavi е богатият набор от функционалности. Клиентите ми са щастливи и споделят в отзивите си, че намират магазина ми за удобен и функционален!`,
            }
        },
        mainSteps: {
            step1Text: "Избери шаблон",
            step2Text: "Качи продуктите си",
            step3Text: "Продавай лесно и успешно",
            step4Text: "Следи и анализирай печалбите си",
            step: 'Стъпка'
        },
        mainMobileFirst: {
            title: 'Оптимизация за мобилни устройства',
            text: `Sellavi предоставя безплатна оптимизация на твоя сайт за мобилни устройства, която гарантира, че магазинът ти ще изглежда безупречно както на компютър, така и на таблет или смартфон.`
        },
        mainFeatures: {
            titleFirstLine: `Всичко, от което`,
            titleSecondLine: `се нуждаеш`,
            text: 'Управлявай всички дейности, свързани с твоя онлайн магазин, от едно място',
            iconText: {
                text1: 'Неограничен хостинг',
                text2: 'Дизайн шаблони',
                text3: 'CRM система',
                text4: 'Безплатен SSL сертификат',
                text5: 'Вградена оптимизация на търсачките (SEO)',
                text6: 'Множество валути',
                text7: 'Множество езици',
                text8: 'Опция за множество администратори',
                text9: 'Вградени онлайн плащания',
                text10: 'Apple Pay и Google Pay',
                text11: 'Управление на фактури',
                text12: 'Статистика и анализ',
                text13: 'Инструменти за маркетинг',
                text14: 'SMS известия',
                text15: 'Facebook Pixel API',
                text16: 'Google Analytics API',
                text17: 'Управление на изоставени колички',
                text18: 'Групи клиенти',
                text19: 'Управление на абонаменти',
                text20: 'Резервации',
                text21: 'Отзиви и рейтинги за продукти',
                text22: 'Изпълнение на поръчки',
                text23: 'Създаване на промокодове',
                text24: 'Блог',
                text25: 'Управление на доставки',
                text26: 'JS и CSS редактори',
                text27: 'Дигитални продукти',
                text28: 'Персонализиране на страницата за поръчка',
                text29: 'API и Webhooks',
                text30: 'Анализ на данни',
                text31: 'Лесна персонализация на сайт модулите',
                text32: 'Връзка с Google ADS',
                text33: 'Екслузивни оферти от нашите партньори',
                text34: 'Instagram Shopping и Facebook Catalog',
                text35: 'Google Shopping',
                text36: 'TikTok Shop',
                text37: 'Сходни продукти',
                text38: 'Страници за продажба',
                text39: 'Видео и снимкови банери',
                text40: 'Филтри за продукти',
                text41: 'Оптимизация за търсене с Google ',
                text42: 'Имейл маркетинг',
            },
            textBtn: 'Започни безплатно!'
        },
        mainCreateAi: {
            titleFirstLine: 'Създай магазина си с помощта на ',
            discriptionBeforeBtn: 'Просто качи снимка на някой свой продукт и наблюдавай как различни прототипи на онлайн магазина ти биват създадени',
            textBtn: 'Качи снимка на продукт',
            orangeTextBtn: 'виж магията',
            loading: 'Loading...',
            startTrialTextBtn: 'Стартирай безплатно',
            placeholderEmail: 'Имейл адрес',
            facebook: {
                textAfterCard: 'Успешна Facebook реклама'
            },
            instagram: {
                textAfterCard: 'Твоят Instagram каталог е пълен с продукти'
            },
            pay: {
                textAfterCard: 'Бърза и удобна поръчка на продуктите ти!'
            },
            adaptive: {
                textAfterCard: 'Сайтът ти се адаптира автоматично към всеки размер екран'
            },
            google: {
                textAfterCard: 'Може да продаваш директно в Google Shopping'
            }
        },
        mainOperates: {
            title: 'Продавай в България и по цял свят',
        },
        mainMagnati: {
            title: 'е наш доверен партньор',
            text: 'за финансовите нужди на твоя бизнес',
            animateText: {
                first: 'Проверка на контрагентите',
                second: 'Дистанционно подписване',
                third: 'Онлайн банкиране',
                forth: 'Депозитни банкомати',
                five: 'Консултация с експерти',
                six: 'Брокерски услуги',
                seven: 'Бизнес адвокат',
                eight: 'Администрация',
                nine: 'Конструктор на документи'
            }
        },
        mainJoin: {
            title: 'Присъедини се към хилядите бизнеси, които вече просперират със Sellavi!',
            joinCards: {
                column1: {
                    title1: 'Магазин за бански костюми',
                    title2: 'Магазин за телефонни калъфи',
                    title3: 'Магазин за бижута',
                    title4: 'Цветарски магазин',
                    title5: 'Магазин за електроуреди ',
                },
                column2: {
                    title1: 'Магазин за бижута',
                    title2: 'Магазин за дрехи',
                    title3: 'Магазин за дигитални картички',
                    title4: 'Магазин за дрехи',
                    title5: 'Бизнес за алуминиеви опаковки',
                    title6: 'Магазин за полилеи и лампи',
                },
                column3: {
                    title1: 'Магазин за мебели',
                    title2: 'Парфюмен магазин',
                    title3: 'Магазин за екшън фигути и настолни игри',
                    title4: 'Магазин за дрехи',
                    title5: 'Почистващи услуги',
                },
                column4: {
                    title1: 'Магазин за дрехи',
                    title2: 'Магазин за дрехи',
                    title3: 'Моряшки магазин',
                    title4: 'Магазин за аксесоари по поръчка',
                    title5: 'Магазин за дрехи',
                },
                column5: {
                    title1: 'Бижутериен магазин',
                    title2: `Магазин за детски дрехи`,
                    title3: 'Магазин за домашни любимци',
                    title4: 'Магазин за здравословни храни',
                    title5: 'Магазин за бански костюми',
                }
            }
        },
        mainSayAboutUs: {
            title: 'Развивай бизнеса си със Sellavi',
            subtitle: 'Ето какво казват нашите успешни клиенти за нас',
            cardFirstTitle: 'Увеличих продажбите си със 70% благодарение на Sellavi',
            cardSecondTitle: 'Всичко необходимо за един успешен бизнес е тук!',
            cardThirdTitle: 'Бяхме готови за продажби след броени дни!',

            authorFirst: 'Клементина Милева',
            authorSecond: 'Altinbas',
            authorThird: 'DB Prive'
        },
        mainLogistic: {
            titleFirstLine: `Интегрирани логистически`,
            titleSecondLine: 'решения',
            subtitle: 'Международни куриерски фирми и логистически центрове - всичко на едно място',
        },
        mainMarketing: {
            titleFirstLine: `Продавай лесно и`,
            titleSecondLine: `успешно`,
            subtitle: 'Разнообразни канали за повишаване на продажбите ти',
        }
    },
    aboutUs: {
        mainTitleNumber: 'Над 250 000',
        mainTitle: 'онлайн магазина преуспяват със Sellavi',
        mainText: `Добре дошъл в Sellavi, ориентирана към търговците глобална платформа за електронна търговия. Тук сме, за да освободим бизнеса ти от безкрайната суматоха свързана с поддръжката на онлайн магазин. Ние приоритизираме теб, гарантирайки ти безпроблемно изживяване в света на електронната търговия.`,
        mainTextLastLine: 'Започни да продаваш днес и ни позволи да оптимизираме пътя ти в онлайн бизнеса!',
        successNumbers: {
            title: 'Резултатите говорят сами за себе си',
            conversionRate: 'Коефициент на преобразуване',
            monthlyVisitors: 'Месечни посетители',
            averageTransaction: 'Средна транзакция',
            transactionsPerDay: 'Транзакции на ден',
            productsStorage: 'Продукти на склад',
            million: "милион",
            over: "над"
        },
        ourStory: {
            title: 'Нашата история',
            textFirstPart: `Историята ни започна през 2015 година с визия за революция в електронната търговия. Събрахме високотехнологичен екип от експерти, за да разработим новаторско решение — обслужване тип "всичко в едно", което трансформира онлайн бизнесите в глобален мащаб. Така се роди Sellavi. `,
            textSecondPart: `Днес гордо оперираме международна компания, обслужваща над 250 000 проспериращи клиента по цял свят. С нашата авангардна SaaS платформа даваме възможност на бизнеси от всякакъв размер да процъфтяват, предоставяйки им основните инструменти за лесно стартиране, работа и прогрес. Тук става въпрос не само за растежа на твоя бизнес, а за създаване на незабравимо шопинг преживяване за клиентите ти. 

            В Sellavi заедно оформяме бъдещето на електронната търговия: присъедини се към нас днес!`
        },
        mission: {
            title: 'Нашата мисия',
            text1: 'Помагаме на бизнеси от всякакъв размер да продават лесно и успешно',
            text2: 'Подпомагаме твоя удобен старт и бърз растеж',
            text3: 'Умело и навременно предоставяме всичко, от което бизнесът ти се нуждае ',
            text4: 'Автоматизираме техническите процеси и оптимизираме управлението на твоя бизнес',
        },
        bestEcomm: {
            titleFirstLine: 'Най-добрата',
            titleSecondLine: 'e-commerce технология'
        },
        globalBlock: {
            title: 'Тези, които започнаха да продават с нас локално, се радват на глобален успех',
            textLeft: 'Sellavi работи по цял свят',
            textRight: 'С нас може да разширяваш аудиторията си ежедневно'
        },
        departments: {
            title: 'Отдели',
            joinBtn: 'Присъедини се към екипа ни',
            cards: {
                card1: 'Проучване и развитие',
                card2: 'Контрол на качеството',
                card3: 'Поддръжка',
                card4: 'E-commerce специалисти',
                card5: 'Маркетинг и дизайн',
                card6: 'Партньори',
                card7: 'Правен отдел',
                card8: 'Кибер сигурност',
                card9: 'Логистика'
            },
            form: {
                title: 'Форма за регистрация',
                textBtn: 'Стани наш партньор',
                textBtnResumeFile: 'Качване на автобиография',
                fullName: 'Пълно име',
                email: 'Електронна поща',
                phone: 'Телефонен номер',
                linkedin: 'LinkedIn профил (по избор)',
                position: 'Позиция, за която кандидатствате',
                why: 'Защо искате да се присъедините към нашия екип?',
                comments: 'Допълнителни коментари (по избор)'
            }
        },
        ourPartners: {
            title: 'Нашите партньори'
        }
    },
    prices: {
        choosePlan: {
            title: 'Избери абонамент',
            trialBtn: 'Тествай безплатно',
            pricePer: 'месец',
            standartPlan: {
                priceNumber: 32,
                priceCurrency: 'лева',
                description: 'Онлайн магазин оптимизиран за максимални продажби: надеждни плащания, маркетинг инструменти, вградени куриерски услуги, инструменти за анализ на данни и 24/7 техническа поддръжка.',
                keyPoints: {
                    point1: 'Неограничен брой продукти',
                    point2: 'Неограничен хостинг',
                    point3: 'Вградена оптимизация на търсачките (SEO)',
                    point4: 'Онлайн плащания',
                    point5: 'БЕЗ допълнителни такси за трансакции',
                    point6: 'Надеждни доставки',
                    point7: 'Дизайн шаблони',
                    point8: 'Рекламни канали: Facebook, Instagram, Google, TikTok, WhatsApp',
                    point9: 'Управление на продукти и поръчки',
                    point10: 'CRM система',
                    point11: 'Цени и отстъпки на едро',
                    point12: 'Промоции и промокодове',
                    point13: 'Множество валути',
                    point14: 'Множество езици',
                    point15: 'Възможност за повече от един администратор',
                    point16: 'Следене на трафика в реално време',
                    point17: 'Свържи собствен домейн',
                    point18: 'Безплатен SSL сертификат',
                    point19: 'Инструменти за анализ на данни',
                },
                keyPointStar: 'при абониране за 3 години',
                keyPointLast: 'Всички бъдещи ъпдейти са безплатни!'
            },
            plusPlan: {
                priceNumber: 299,
                priceCurrency: 'лева',
                description: `Окрили бизнеса си с нашия премиум план, включващ личен e-commerce специалист, персонализирана поддръжка на онлайн магазина ти и сайт дизайн, създаден специално за теб.
                `,
                keyPointsPlus: {
                    title: 'Всичко в стандартния ни Sellavi план, плюс:',
                    point1: 'Личен e-commerce специалист',
                    point2: 'Дизайн, създаден специално за теб',
                    point3: 'Многоканална техническа поддръжка',
                }
            }
        },
        features: {
            title: 'Сравни абонаментни планове ',
            textBtnCreateAcc: 'Създай профил',
            textBtnTrial: 'Безплатен пробен период',
            sales: {
                title: 'Канали за продажба',
                items: {
                    title1: 'Онлайн магазин',
                    title2: 'Instagram Shop',
                    title3: 'Facebook каталог',
                    title4: 'TikTok Shop',
                    title5: 'Google Shopping',
                    title6: 'WhatsApp',
                    title7: 'SMS',
                    title8: 'Нюзлетър'
                }
            },
            onlineStore: {
                title: 'Онлайн магазин',
                items: {
                    title1: 'Управление на поръчките',
                    title2: 'Организация на продуктите',
                    title3: 'Следене на инвентара',
                    title4: 'Управление на работата с клиенти',
                    title5: 'Опция за предлагане на резервации',
                    title6: 'Опция за оставяне на клиентски отзиви',
                    title7: 'Дизайн шаблони',
                    title8: 'Многоезичен онлайн магазин',
                    title9: 'Възможност за създаване на клиентска регистрация',
                    title10: 'Купи или свържи собствен домейн',
                    title11: 'Безплатен SSL сертификат',
                    title12: 'Организация и филтриране на продукти',
                    title13: 'Устойчива количка',
                    title14: 'Бърза и удобна страница за покупка',
                    title15: 'Опция за правене на поръчки без регистрация',
                    title16: 'Персонализация на страницата за поръчки',
                    title17: 'Опция за ограничаване на поръчки',
                    title18: 'GDPR'
                }
            },
            admin: {
                title: 'Админ панел',
                items: {
                    title1: 'Анализ на данни в реално време',
                    title2: 'Бонус функции за твоя магазин',
                    title3: 'Многоезичен админ панел',
                    title4: 'Опция за множество администратори',
                    title5: 'Опция за даване на частични админ права',
                    title6: 'Режим "Сайт под ремонт"',
                    title7: "Достъп до API на Sellavi",
                    title8: 'Достъп до Webhooks'
                }
            },
            productPrice: {
                title: 'Продукти, цени и инвентар',
                items: {
                    title1: 'Неограничен брой продукти',
                    title2: 'Неограничен брой категории',
                    title3: 'Неограничен хостинг',
                    title4: 'Опция за продажба на дигитални продукти',
                    title5: 'Съвременна система за поддръжка на инвентара',
                    title6: 'Възможност за предлагане на сходни продукти',
                    title7: 'Препоръчани продукти',
                    title8: 'Рейтинги и отзиви за продукти',
                    title9: 'Скрити продукти',
                    title10: 'Скрити категории',
                    title11: 'Скрити цени',
                    title12: 'Насрочване на намаления',
                    title13: 'Опция за добавяне на цени на едро',
                    title14: 'Многовалутен онлайн магазин',
                    title15: 'Опции за продукти',
                    title16: 'Филтри за продукти',
                    title17: 'Промо стикери',
                    title18: 'Категоризация според марката',
                    title19: 'Импорт/ Експорт',
                    title20: 'Редактиране на много продукти наведнъж'
                }
            },
            shipping: {
                title: "Куриерски услуги и доставки",
                items: {
                    title1: 'Вградени куриерски услуги',
                    title2: 'Международни доставки',
                    title3: 'Калкулиране на цената за доставка',
                    title4: 'Възможност за добавяне на собствени методи за доставка',
                    title5: 'Опция за добавяне на условия за доставка',
                    title6: 'Доставка по зони',
                    title7: 'Доставки за конкретни продукти',
                    title8: 'Лесно управление на доставките',
                    title9: 'Вградена система за следене на доставки',
                    title10: 'Логистически решения',
                    title11: 'Складове за съхранение',
                    title12: 'Пунктове за вземане на пратки'
                }
            },
            payments: {
                title: 'Плащания',
                items: {
                    title1: 'Вградени методи за онлайн плащания',
                    title2: 'Apple Pay and Google Pay',
                    title3: 'Безплатно свързване на vPOS',
                    title4: 'Преференциални такси за трансакции',
                    title5: 'Онлайн фактуриране',
                    title6: 'Наложен платеж',
                    title7: 'Банков превод',
                    title8: 'Повтарящи се плащания',
                    title9: 'Персонализация на страницата за поръчка'
                }
            },
            design: {
                title: "Дизайн",
                items: {
                    title1: 'Богат от дизайн шаблони',
                    title2: 'Управление на модулите на всяка страница',
                    title3: 'CSS/JS редактори',
                    title4: 'Видео банери',
                    title5: 'Инструменти за редактиране на съдържание',
                    title6: 'Бърза модификация на дизайна',
                    title7: 'Настройки за мобилна версия',
                    title8: 'Персонализиран дизайн ',
                }
            },
            orders: {
                title: 'Поръчки',
                items: {
                    title1: 'Неограничен брой поръчки',
                    title2: 'Директно управление на поръчките',
                    title3: 'Без допълнителни такси',
                    title4: 'Ръчно добавяне на поръчки',
                    title5: 'Редактиране на поръчки',
                    title6: 'Следене на статуса на поръчките',
                    title7: 'Дигитален генератор на фактури',
                    title8: 'Известия относно нови поръчки чрез имейл, SMS и Телеграм',
                    title9: 'Управление на направените резервации',
                    title10: 'Експортиране на поръчки',
                    title11: '30-дневно пазене на съдържанието на изоставените колички'
                }
            },
            clients: {
                title: 'Клиенти',
                items: {
                    title1: 'Система за управление',
                    title2: 'История на поръчките',
                    title3: 'Лични профили за клиентите',
                    title4: 'Опция за повторна поръчка',
                    title5: 'Запаметяване на множество адреси',
                    title6: 'Групи клиенти',
                    title7: 'Персонализирано потребителско преживяване',
                    title8: 'Опция за добавяне на допълнителни полета с информация',
                    title9: 'Импорт/ Експорт',
                    title10: 'Опция за редактиране на много клиентски профили наведнъж'
                }
            },
            marketing: {
                title: 'Маркетинг и реклама',
                items: {
                    title1: 'Вградено SEO',
                    title2: 'Google Ads',
                    title3: 'Facebook Пиксел',
                    title4: 'Facebook Conversions API',
                    title5: 'Опция за създаване на разнообразни намаления',
                    title6: 'Намаления и промокодове',
                    title7: 'Генератор на промокодове',
                    title8: 'Подаръчни ваучери',
                    title9: 'Страници за продажба',
                    title10: 'Вграден блог',
                    title11: 'Автоматично добавяне на категория "Намалени продукти"',
                    title12: 'Редактор на SEO мета тагове',
                    title13: 'Robots.txt редактор',
                    title14: 'Автоматично генериран sitemap.xml',
                    title15: '301 redirects'
                }
            },
            analitics: {
                title: 'Анализ на данни',
                items: {
                    title1: 'Следене на данни в реално време',
                    title2: 'Информация за изоставени колички',
                    title3: 'Информация относно употребата на промокодове',
                    title4: 'Google Analytics GA4',
                    title5: 'Google Analytics API',
                    title6: 'Google tag manager'
                }
            },
            security: {
                title: 'Сигурност',
                items: {
                    title1: 'Вграден Firewall',
                    title2: 'PCI DSS ниво 1',
                    title3: 'SSL сертификат',
                    title4: 'DDoS защита',
                    title5: 'Защита от спам',
                    title6: '24/7 техническа поддръжка',
                    title7: 'GDPR'
                }
            },
            communication: {
                title: 'Комуникации',
                items: {
                    title1: 'Лесно свързване на WhatsApp',
                    title2: 'Лесно свързване на Facebook',
                    title3: 'Шаблони за съобщения',
                    title4: 'Персонализирани динамични клиентски данни',
                    title5: 'Известия за промяна в статуса на дадена поръчка',
                    title6: 'SMS известия',
                    title7: 'Имейл известия',
                    title8: 'Инструменти за имейл маркетинг',
                    title9: 'Свързване на приложения по твое желание'
                }
            },
            serviceSupport: {
                title: 'Техническа поддръжка',
                items: {
                    title1: 'Богата база данни',
                    title2: 'Чат с нашия отдел за техническа поддръжка',
                    title3: 'Опция за комуникация чрез имейл',
                    title4: 'Мултиканална техническа поддръжка',
                    title5: 'Личен e-commerce специалист',
                    title6: 'Незабавна реакция в случай на проблем'
                }
            }
        },
        faq: {
            title: 'Често задавани въпроси',
            text: 'Всичко, което трябва да знаеш относно платформата и плащанията',
            questionAnswer: {
                question1: 'Нужно ли е да разбирам от уеб дизайн и разработка, за да ползвам Sellavi?',
                answer1: `Категорично не! Интуитивният интерфейс на Sellavi ти позволява да персонализираш магазина си в рамките на броени минути дори и без да имаш знания по програмиране. Това означава, че може да се фокусираш върху истински важните неща - твоите продукти и клиенти. 

                Имаш нужда от помощ? Нашата изчерпателна база данни съдържа отговорите на повечето ти въпроси, а отзивчивият ни отдел за техническа поддръжка е винаги на линия: просто кликни на зелената чат икона в долния десен ъгъл на Sellavi админ панела, за да се свържеш с тях.`
                ,
                question2: 'Мога ли да преместя вече съществуващ онлайн магазин към Sellavi?',
                answer2: `Без проблем! Нашите специалисти по техническа поддръжка са винаги готови да ти помогнат с преместването на твоя онлайн магазин. Просто трябва да ни предоставиш експорт таблици с продуктите и клиентите си и онлайн магазинът ти ще е готов за нула време (спокойно - няма да изгубиш данни по време на този процес)!
                `,
                question3: 'Как да сменя абонаментния си план?',
                answer3: `"Да преминеш от абонамент Sellavi на Sellavi Plus е лесно! Просто следвай следните 4 стъпки:

                1) Влез в своя админ панел.
                2) Кликни върху номера на магазина ти в долния ляв ъгъл.
                3) Щракни върху ""Абонаментен план"".
                4) Избери абонамента*, към който искаш да преминеш, и потвърди избора си.
                
                Това е всичко! Tвоят абонаментен план ще бъде променен автоматично.
                
                *Може също така да комбинираш двата абонамента по свой вкус, подбирайки кои елементи искаш да ползваш от всеки. За повече детайли по темата се свържи с отдела ни за техническа поддръжка!"`,
                question4: 'Мога ли да ползвам собствен домейн?',
                answer4: `Разбира се! Може да свържеш вече съществуващ домейн към своя Sellavi онлайн магазин, независимо от това на кой абонамент си се спрял.
                `,
                question5: 'Мога ли да управлявам магазина си чрез мобилно устройство?',
                answer5: `Да! Sellavi платформата е оптимизирана за мобилна употреба, така че предоставя оптимален комфорт както за собствениците на онлайн магазин, така и за техните клиенти. Гъвкавите ни дизайни са разработени така, че да представят сайта ти в най-добра светлина на всяко устройство и да повишат продажбите ти.
                `,
                question6: 'Продавам богат набор от продукти. Има ли лимит на хостинга и това колко артикула мога да кача в магазина си?',
                answer6: 'Sellavi се гордее с безлимитния си хостинг и ти позволява да качваш неограничен брой артикули. Независимо дали продаваш само няколко или множество продукти, ние сме точната платформа!',
                question7: 'Предлагате ли инструменти за анализ на продажби и данни?',
                answer7: 'Абсолютно! Sellavi предлага интуитивен админ панел, с който може в реално време да следиш своите приходи и продажби.',
                question8: 'Трябва ли да плащам за трафик и съхранение на данни?',
                answer8: "Не те таксуваме за трафика, който генерираш. Също така независимо от абонамента, който имаш, може безплатно да сваляш и пазиш неограничено количество данни.",
                question9: 'Предлагате ли отстъпки за неправителствени организации?',
                answer9: `Да, неправителствените организации имат право на отстъпка. Моля, свържи се с нас на <a class="faq__item-info-answer-link" href="mailto:support.bg@sellavi.com" target="_blank">support.bg@sellavi.com</a> и ни разкажи за своята организация, за да ти предложим специална оферта.`,
            }
        },
        benefits: {
            title: 'С всеки план получаваш',
            text: 'Без дребен шрифт: получаваш това, което виждаш',
            cards: {
                card1: 'Неограничен хостинг и място за съхранение',
                card2: 'Интеграция с мощни маркетинг инструменти',
                card3: 'Сигурни плащания и защита на данните',
                card4: '24/7 техническа поддръжка',
                card5: 'Вградени логистически решения',
                card6: 'Вградени методи за плащане'
            },
            textTrialBtn: 'Тествай безплатно'
        },
        suitable: {
            title: 'Идеалното решение за всеки бизнес',
            chartText: 'от клиентите в избрания сегмент',
            small: {
                title: 'Малки',
                mainDescriptions: `от процъфтяващите малки бизнеси избират Sellavi`,
                whyPoints1: `Спестяват до 85% от бюджета си`,
                whyPoints2: `Автоматизацията на бизнес процесите спестява 75% от времето за поддръжка`,
                whyPoints3: `Редовно обновяване на платформата, удобни инструменти за анализ на продажбите и 24/7 техническа поддръжка`,
            },
            medium: {
                title: 'Средни',
                mainDescriptions: `от бизнесите, генериращи над 14 000 лева месечно, се доверяват на Sellavi за успеха си`,
                whyPoints1: `Получаваш личен мениджър, който ти дава подкрепа и маркетинг консултации `,
                whyPoints2: `Управляваш всички бизнес и логистически процеси от един съвременен и удобен панел`,
                whyPoints3: `Персонализирани настройки на магазина, които отговарят на специфичните ти нужди`,
            },
            large: {
                title: 'Големи',
                mainDescriptions: `от големите бизнеси залагат на Sellavi, постигайки впечатляващ ръст в продажбите от 35%`,
                whyPoints1: `Пълен контрол над всички бизнес операции`,
                whyPoints2: `Лесна свързване на редица маркетингови, платежни, куриерски и аналитични инструменти`,
                whyPoints3: `Гъвкави условия, които пасват на твоите специфични нужди`,
            },
            why: 'Защо?'
        }
    },
    affiliateProgram: {
        title: "Партньорската програма на Sellavi",
        text: "Запознай се с нея и стани наш бизнес партньор!",
        mobileCards: {
            card1: {
                text: "Общо продажби"
            },
            card2: {
                text: "Изоставени колички"
            },
            card3: {
                text: "Брой поръчки"
            },
            card4: {
                text: "Изоставени артикули"
            }
        },
        pros: {
            title: "Партнирай си със Sellavi",
            cardsTitle: {
                card1: 'Комисионна за всеки нов клиент',
                card2: 'Приоритетни насоки и техническа поддръжка',
                card3: 'Специални маркетинг кампании',
                card4: 'Възможности за разрастване на всеки бизнес'
            }
        },
        overview: {
            title: 'За програмата',
            titleSecond: 'Печели със Sellavi',
            textFirst: `Партньорската ни програма приветства богат набор от професионалисти, вариращи от уеб разработчици до дигитални агенции, и предлага преференциални условия за твоите клиенти. С нейна помощ може да подготвиш аудиторията си да развива успешни онлайн бизнеси с нашата платформа.`,
            textSecond: `Това начинание ти позволява не само да се утвърдиш като лидер в своята сфера, но и да получаваш отлични комисионни за препоръка.
            Записването за програмата е напълно безплатно, така че се присъедини към нас днес и започни да успяваш!`,
            cardsMakeMoney: {
                card1: 'Покани клиентите си да създадат онлайн магазин с нашата платформа',
                card2: "Дай им промокод, за да знаем, че са твои клиенти",
                card3: "Ще получаваш по 20% комисионна за всеки клиент, който използва кода ти и плати за някой от нашите планове"
            }
        },
        target: {
            title: 'За кого е нашата програма?',
            cardsTarget: {
                title1: "Уебсайт разработчици ",
                title2: "Дизайн професионалисти",
                title3: "Копирайтъри и контент криейтъри",
                title4: "Бизнес консултанти",
                title5: "Маркетинг",
                title6: "Лични треньори",
                title7: "Специалисти по партньорски програми",
                title8: "Предприемачи",
                title9: "SEO агенции",
                title10: "SEM (Search Engine Marketing) компании",
                title11: "Компании за социално медиен маркетинг",
                title12: "Мениджъри на клиентски групи",
                title13: "Инфлуенсъри",
                title14: "E-commerce сайт мениджъри",
            }
        },
        form: {
            title: 'НОВИ ХОРИЗОНТИ',
            text: 'за твоите партньори и бизнес',
            registration: 'Формуляр за регистрация',
            fullName: 'Пълно име',
            email: 'Имейл',
            phone: 'Телефонен номер (по желание)',
            business: 'Име на компанията (по желание)',
            occupation: 'Длъжност (по желание)',
            bank_name: 'Име на банката (по желание)',
            bank_branch: 'Клон на банката (по желание)',
            bank_account: 'Банкова сметка (по желание)',
            bank_holder_name: 'Име на титуляра на сметката (по желание)',
            checkLabel: `Потвърждавам, че съм прочел и съм съгласен с <a href='/#' target='_blank'>договора за сътрудничество</a>`,
            textBtn: 'Стани наш партньор',
            successText: 'Успешно изпратихме вашата заявка за партньорство'
        }
    },
    blog: {
        title: 'Тайните за e-commerce успех',
        subscribeForm: {
            title: `Искаш да си в крак с последните e-commerce новини? Абонирай се за нашия нюзлетър!`,
            textBtn: 'Абонирай се',
            placeholder: 'Имейл адрес',
        },
        tags: {
            smm: 'Social Media / or SMM',
            ecomm: 'E-commerce',
            startup: 'Как да започнеш',
            onlinestore: 'Онлайн магазин',
            tech: 'Технологии',
            marketing: 'Маркетинг'
        },
        followInSocial: 'Последвай Sellavi в социалните мрежи',
        emptyStatus: 'Празен'
    }
}